import { ApplicationScope } from "@superblocksteam/shared";
import { debounce } from "lodash";
import React, { lazy, Suspense } from "react";
import { Converter } from "showdown";
import Skeleton from "legacy/components/utils/Skeleton";
import { EventType } from "legacy/constants/ActionConstants";
import {
  PropsPanelCategory,
  type PropertyPaneConfig,
} from "legacy/constants/PropertyControlConstants";
import { WidgetType } from "legacy/constants/WidgetConstants";
import { VALIDATION_TYPES } from "legacy/constants/WidgetValidation";
import {
  WidgetPropertyValidationType,
  BASE_WIDGET_VALIDATION,
} from "legacy/constants/WidgetValidation";
import { retryPromise } from "legacy/utils/Utils";
import { createRunEventHandlersPayloadOptional } from "legacy/utils/actions";
import { ANIMATE_LOADING_PROPERTY_CONTROL_HELP_TEXT } from "pages/Editors/AppBuilder/Sidebar/PropertyControlCommons";
import BaseWidget from "../BaseWidget";
import { DerivedPropertiesMap } from "../Factory";
import { sizeSection, visibilitySection } from "../basePropertySections";
import { getPopoverConfig } from "../eventHandlerPanel";
import { typographyProperties } from "../styleProperties";
import withMeta from "../withMeta";
import { DEFAULT_RTE_WIDGET_LABEL_TEXT_STYLE_VARIANT } from "./constants";
import { RichTextEditorWidgetProps } from "./types";

const RichTextEditorComponentWithManagedLayout = lazy(() =>
  retryPromise(
    () => import(/* webpackChunkName: "rte" */ "./RichTextEditorComponent"),
  ),
);

const converter = new Converter();
converter.setOption("simpleLineBreaks", true);

class RichTextEditorWidget extends BaseWidget<
  RichTextEditorWidgetProps,
  { localValue: string; isFocused: boolean }
> {
  static getPropertyPaneConfig(): PropertyPaneConfig[] {
    return [
      {
        sectionName: "General",
        children: [
          {
            helpText: "Sets a label text for the text editor",
            propertyName: "label",
            label: "Label",
            controlType: "INPUT_TEXT",
            placeholderText: "Enter label text",
            isBindProperty: true,
            isTriggerProperty: false,
            visibility: "SHOW_NAME",
            isRemovable: true,
            defaultValue: "Label",
            propertyCategory: PropsPanelCategory.Content,
          },
          ...typographyProperties({
            defaultVariant: DEFAULT_RTE_WIDGET_LABEL_TEXT_STYLE_VARIANT,
            textStyleParentDottedPath: "labelProps",
            propertyNameForHumans: "Label",
            hiddenIfPropertyNameIsNullOrFalse: "label",
          }),
          {
            propertyName: "defaultValue",
            helpText:
              "Sets the default value of the component, parsed by mode. If the default text changes it will overwrite the user input.",
            label: "Default value",
            controlType: "INPUT_TEXT",
            placeholderText: "Enter HTML",
            isBindProperty: true,
            isTriggerProperty: false,
            propertyCategory: PropsPanelCategory.Content,
          },
          {
            propertyName: "enableMenuBar",
            label: "File menu",
            helpText:
              "Controls whether the menu bar is shown, which exposed more advanced editing options.",
            controlType: "SWITCH",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            defaultValue: true,
            propertyCategory: PropsPanelCategory.Appearance,
          },
          {
            propertyName: "convertMarkdown",
            label: "Convert markdown",
            helpText:
              "If enabled, markdown in the default value will automatically be converted to rich text.",
            controlType: "SWITCH",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            propertyCategory: PropsPanelCategory.Interaction,
          },
          {
            propertyName: "animateLoading",
            label: "Loading animation",
            helpText: ANIMATE_LOADING_PROPERTY_CONTROL_HELP_TEXT,
            controlType: "SWITCH",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            propertyCategory: PropsPanelCategory.Appearance,
          },
          {
            propertyName: "isDisabled",
            label: "Disabled",
            helpText: "Disables user interaction with this component",
            controlType: "SWITCH",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: VALIDATION_TYPES.BOOLEAN,
            propertyCategory: PropsPanelCategory.Interaction,
          },
        ],
      },
      sizeSection(),
      {
        sectionName: "Validation",
        sectionCategory: PropsPanelCategory.Interaction,
        children: [
          {
            propertyName: "isRequired",
            label: "Required",
            helpText: "Makes input to the component mandatory",
            controlType: "SWITCH",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
          },
          {
            propertyName: "customValidationRule",
            label: "Validation",
            helpText: "Sets a custom validation rule",
            controlType: "INPUT_TEXT",
            placeholderText: "Enter validation rule",
            isBindProperty: true,
            isTriggerProperty: false,
          },
        ],
      },
      visibilitySection({ useJsExpr: false }),
      {
        sectionName: "Actions",
        sectionCategory: PropsPanelCategory.EventHandlers,
        children: [
          getPopoverConfig(
            "onTextChanged",
            "Triggers an action when the text is changed",
          ),
        ],
      },
    ];
  }

  static getDerivedPropertiesMap(): DerivedPropertiesMap {
    return {
      validationErrors: `{{
        (() => {
          const errors = {};
          if (this.isRequired && !this.value && !this.value.length) {
            errors.isRequired = "This field is required";
          }
          if (this.customValidationRule && this.customValidationRule.length && this.customValidationRule !== "true") {
            errors.customValidationRule = "Invalid value";
          }
          return errors;
        })()
      }}`,
      isValid: `{{Object.keys(this.validationErrors ?? {}).length === 0}}`,
    };
  }

  static getPropertyValidationMap(): WidgetPropertyValidationType {
    return {
      ...BASE_WIDGET_VALIDATION,
      value: VALIDATION_TYPES.TEXT,
      defaultValue: VALIDATION_TYPES.TEXT,
      enableMenuBar: VALIDATION_TYPES.BOOLEAN,
      convertMarkdown: VALIDATION_TYPES.BOOLEAN,
      customValidationRule: VALIDATION_TYPES.TEXT,
      animateLoading: VALIDATION_TYPES.BOOLEAN,
      label: VALIDATION_TYPES.TEXT,
      isRequired: VALIDATION_TYPES.BOOLEAN,
    };
  }

  static getMetaPropertiesMap(): Record<string, any> {
    return {
      value: undefined,
      isTouched: false,
    };
  }

  static getDefaultPropertiesMap(): Record<string, string> {
    return {
      value: "defaultValue",
    };
  }

  constructor(props: RichTextEditorWidgetProps) {
    super(props);
    // If there is a meta prop already set, use it.
    this.state = {
      localValue: props.value ?? props.defaultValue ?? "",
      isFocused: false,
    };
  }

  componentDidMount = () => {
    if (this.props.convertMarkdown) {
      this.onValueChange(
        converter.makeHtml(String(this.props.defaultValue)).toString(),
        false,
      );
    }
  };

  isInvalid = () => {
    if (!this.props.isTouched) return false;
    if (this.props.validationErrors?.customValidationRule) return true;
    if (this.props.validationErrors?.isRequired) {
      // check the local value, if its set return false
      return !this.state.localValue;
    }

    return !this.props.isValid;
  };

  componentDidUpdate = (prevProps: Readonly<RichTextEditorWidgetProps>) => {
    // Reset local state if the value has updated via default value
    if (
      prevProps.defaultValue !== this.props.defaultValue ||
      prevProps.convertMarkdown !== this.props.convertMarkdown
    ) {
      if (this.props.convertMarkdown) {
        this.onValueChange(
          converter.makeHtml(String(this.props.defaultValue)).toString(),
          false,
        );
      } else {
        this.onValueChange(this.props.defaultValue ?? "", false);
      }
    }
    if (
      prevProps.value !== this.props.value &&
      this.props.value !== this.state.localValue &&
      !this.state.isFocused
    ) {
      this.setState({ localValue: this.props.value ?? "" });
    }
  };

  onValueChange = (content: string, wasUserInput: boolean = true) => {
    this.setState({ localValue: content });
    this.onValueChangeDebounced(content, wasUserInput);
    if (!this.props.isTouched && wasUserInput) {
      this.props.updateWidgetMetaProperty("isTouched", true);
    }
  };

  onValueChangeDebounced = debounce((text: string, wasUserInput: boolean) => {
    this.props.updateWidgetMetaProperty(
      "value",
      text,
      createRunEventHandlersPayloadOptional({
        steps: this.props.onTextChanged,
        currentScope: ApplicationScope.PAGE,
        type: EventType.ON_TEXT_CHANGE,
        entityName: this.props.widgetName,
      }),
    );
  }, 200);

  componentWillUnmount() {
    this.onValueChangeDebounced.flush();
  }

  onFocus = () => {
    this.setState({ isFocused: true });
  };

  onBlur = () => {
    this.setState({ isFocused: false });
    if (!this.props.isTouched) {
      this.props.updateWidgetMetaProperty("isTouched", true);
    }
  };

  getPageView() {
    return (
      <Suspense fallback={<Skeleton />}>
        <RichTextEditorComponentWithManagedLayout
          {...this.props}
          onValueChange={this.onValueChange}
          currentValue={this.state?.localValue || ""}
          onBlur={this.onBlur}
          onFocus={this.onFocus}
          isValid={!this.isInvalid()}
        />
      </Suspense>
    );
  }

  getWidgetType(): WidgetType {
    return "RICH_TEXT_EDITOR_WIDGET";
  }
}

export default RichTextEditorWidget;
export const ConnectedRichTextEditorWidget = withMeta(RichTextEditorWidget);
