import { Classes } from "@blueprintjs/core";
import { ApplicationScope } from "@superblocksteam/shared";
import { castArray, isArray, isEqual, isPlainObject } from "lodash";
import { debounce } from "lodash";
import React from "react";
import styled from "styled-components";
import {
  EventType,
  MultiStepDef,
  RunEventHandlersPayload,
} from "legacy/constants/ActionConstants";
import {
  PropsPanelCategory,
  type PropertyPaneConfig,
} from "legacy/constants/PropertyControlConstants";
import { WidgetType, WIDGET_PADDING } from "legacy/constants/WidgetConstants";
import { VALIDATION_TYPES } from "legacy/constants/WidgetValidation";
import {
  WidgetPropertyValidationType,
  BASE_WIDGET_VALIDATION,
} from "legacy/constants/WidgetValidation";

import { TextStyleWithVariant } from "legacy/themes";
import {
  createRunEventHandlersPayload,
  createRunEventHandlersPayloadOptional,
} from "legacy/utils/actions";
import { ANIMATE_LOADING_PROPERTY_CONTROL_HELP_TEXT } from "pages/Editors/AppBuilder/Sidebar/PropertyControlCommons";
import { Flags } from "store/slices/featureFlags";
import {
  getComponentErrorMessage,
  hasInvalidPropsInComponent,
} from "utils/error/error";
import { getComponentDimensions } from "utils/size";
import BaseWidget, { WidgetPropsRuntime, WidgetState } from "../BaseWidget";
import { labelStyle } from "../Shared/widgetLabelStyles";
import {
  labelPositionProperty,
  sizeSection,
  visibleProperties,
} from "../basePropertySections";
import { getPopoverConfig } from "../eventHandlerPanel";
import { updateHeightOnAddOrRemove } from "../propsPanelUtils";
import { textStyleControls } from "../styleProperties";
import withMeta, { WithMeta } from "../withMeta";
import { DropdownComponentWithLayoutManaged } from "./DropdownComponentWIthLayoutManaged";
import {
  DEFAULT_DROPDOWN_WIDGET_LABEL_STYLE_VARIANT,
  DEFAULT_DROPDOWN_WIDGET_INPUT_STYLE_VARIANT,
} from "./constants";
import {
  normalizeFunctionStringified,
  normalizeFunction,
} from "./normalizeValues";
import { DropdownOption } from "./types";

interface DropdownStyleOverridesProps {
  $height: number;
  hasLabel?: boolean;
  vertical?: boolean;
}

const DropdownStyleOverrides = styled.div<DropdownStyleOverridesProps>`
  overflow-y: hidden;

  .${Classes.INPUT} {
    height: ${(props) =>
      props.vertical ? "100%" : `${props.$height - WIDGET_PADDING * 2}px`};
  }

  &&& label {
    ${(props) => (props.vertical ? labelStyle.vertical : labelStyle.horizontal)}
  }
`;

class DropdownWidget extends BaseWidget<DropdownWidgetProps, WidgetState> {
  static getPropertyPaneConfig(): PropertyPaneConfig[] {
    return [
      {
        sectionName: "General",
        children: [
          {
            helpText: "Text to be shown above the dropdown",
            propertyName: "label",
            label: "Label",
            controlType: "INPUT_TEXT",
            placeholderText: "Enter placeholder text",
            isBindProperty: true,
            isTriggerProperty: false,
            visibility: "SHOW_NAME",
            isRemovable: true,
            defaultValue: "Label",
            updateHook: updateHeightOnAddOrRemove,
            propertyCategory: PropsPanelCategory.Content,
          },
          labelPositionProperty({ orientation: "legacy" }),
          ...textStyleControls({
            key: "labelProps",
            propertyName: "label",
            textStyleLabel: "Label text",
            colorLabelText: "Label color",
            defaultVariant: DEFAULT_DROPDOWN_WIDGET_LABEL_STYLE_VARIANT,
          }),
          {
            propertyName: "icon",
            label: "Icon",
            helpText:
              "Select an icon to be displayed in the dropdown input. If the selected option has its own icon, it will override this icon",
            controlType: "ICON_SELECTOR",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            customJSControl: "CODE_EDITOR_ICON_SELECTOR",
            propertyCategory: PropsPanelCategory.Appearance,
          },
          {
            helpText:
              "Allows users to select either a single option or multiple options. Values must be unique",
            propertyName: "options",
            forceVertical: true,
            label: "Options",
            controlType: "LABEL_VALUE_CONTROL",
            placeholderText: '[{label: "label1", value: "value2"}]',
            isBindProperty: true,
            isTriggerProperty: false,
            propertyCategory: PropsPanelCategory.Content,
          },
          {
            helpText: "Text to be shown inside of placeholder",
            propertyName: "placeholderText",
            label: "Placeholder",
            controlType: "INPUT_TEXT",
            placeholderText: "Enter placeholder text",
            isBindProperty: true,
            isTriggerProperty: false,
            propertyCategory: PropsPanelCategory.Appearance,
          },
          ...textStyleControls({
            key: "inputProps",
            textStyleLabel: "Input text",
            colorLabelText: "Input text color",
            defaultVariant: DEFAULT_DROPDOWN_WIDGET_INPUT_STYLE_VARIANT,
          }),
        ],
      },
      sizeSection({
        heightSupportsFitContent: (flags: Flags) => {
          return false; // TODO - needs product/design due to multiselect behavior
          // return Boolean(flags[Flag.ENABLE_TYPOGRAPHY]);
        },
      }),
      {
        sectionName: "Advanced",
        children: [
          {
            helpText: `Matches the "value" field from options. Can be an array when using multi-select`,
            propertyName: "defaultOptionValue",
            label: "Default selected value(s)",
            controlType: "INPUT_TEXT",
            placeholderText: "Enter option value",
            isBindProperty: true,
            isTriggerProperty: false,
            propertyCategory: PropsPanelCategory.Content,
          },

          {
            helpText:
              "Allows users to select either a single option or multiple options",
            propertyName: "isMultiple",
            label: "Multi-select",
            controlType: "SWITCH",
            isBindProperty: false,
            isTriggerProperty: false,
            propertyCategory: PropsPanelCategory.Interaction,
          },
          {
            helpText: "Enable a button to select/deselect all options",
            propertyName: "allowSelectAll",
            label: "Select/Deselect all",
            controlType: "SWITCH",
            isBindProperty: false,
            isTriggerProperty: false,
            hidden: (props: DropdownWidgetProps) => !props.isMultiple,
            propertyCategory: PropsPanelCategory.Interaction,
          },
          {
            helpText: "Allows users to clear the dropdown value",
            propertyName: "allowClearing",
            label: "Clearable",
            controlType: "SWITCH",
            isBindProperty: false,
            isTriggerProperty: false,
            hidden: (props: DropdownWidgetProps) => props.isMultiple,
            propertyCategory: PropsPanelCategory.Interaction,
          },
          {
            helpText:
              "Enable client-side typeahead filtering of options via fuzzy matching. When implementing server-side filtering, we recommend disabling this setting to avoid flickering",
            propertyName: "clientSideFiltering",
            label: "Client-side filtering",
            controlType: "SWITCH",
            isBindProperty: true,
            isTriggerProperty: false,
            validation: VALIDATION_TYPES.BOOLEAN,
            isJSConvertible: true,
            defaultValue: true,
            propertyCategory: PropsPanelCategory.Interaction,
          },
          {
            propertyName: "isRequired",
            label: "Required",
            helpText: "Makes input to the component mandatory",
            controlType: "SWITCH",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: VALIDATION_TYPES.BOOLEAN,
            propertyCategory: PropsPanelCategory.Interaction,
          },
          {
            helpText: ANIMATE_LOADING_PROPERTY_CONTROL_HELP_TEXT,
            propertyName: "animateLoading",
            label: "Loading animation",
            controlType: "SWITCH",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            propertyCategory: PropsPanelCategory.Appearance,
          },
          ...visibleProperties({ useJsExpr: false }),
          {
            propertyName: "isDisabled",
            label: "Disabled",
            helpText: "Disables user interaction with this component",
            controlType: "SWITCH",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: VALIDATION_TYPES.BOOLEAN,
            propertyCategory: PropsPanelCategory.Interaction,
          },
        ],
      },
      {
        sectionName: "Validation",
        sectionCategory: PropsPanelCategory.Interaction,
        children: [
          {
            propertyName: "customValidationRule",
            label: "Validation",
            helpText:
              "Sets a custom validation rule for the dropdown. Expects true or false.",
            controlType: "INPUT_TEXT",
            placeholderText: "{{/^[a-z]+$/.test(Input1.value)}}",
            isBindProperty: true,
            isTriggerProperty: false,
            isJSConvertible: true,
          },
          {
            propertyName: "customErrorMessage",
            label: "Error",
            helpText:
              "Sets a custom message to display in a popover when validation doesn't pass",
            controlType: "INPUT_TEXT",
            placeholderText: "Enter validation message",
            isBindProperty: true,
            isTriggerProperty: false,
            isJSConvertible: true,
          },
        ],
      },
      {
        sectionName: "Actions",
        sectionCategory: PropsPanelCategory.EventHandlers,
        children: [
          getPopoverConfig(
            "onOptionChange",
            "Triggers an action when a user selects an option",
          ),
          getPopoverConfig(
            "onSearchTextChange",
            "Triggers an action when a user types in the search box",
          ),
          getPopoverConfig(
            "onClear",
            "Triggers an action when a user explicitly clears the selected option(s)",
            {
              visibility: "DEFAULT",
            },
          ),
        ],
      },
    ];
  }
  static getPropertyValidationMap(): WidgetPropertyValidationType {
    return {
      ...BASE_WIDGET_VALIDATION,
      placeholderText: VALIDATION_TYPES.TEXT,
      label: VALIDATION_TYPES.TEXT,
      options: VALIDATION_TYPES.OPTIONS_DATA,
      isMultiple: VALIDATION_TYPES.BOOLEAN,
      isRequired: VALIDATION_TYPES.BOOLEAN,
      isVertical: VALIDATION_TYPES.BOOLEAN,
      selectedOptionValues: VALIDATION_TYPES.ARRAY,
      defaultOptionValue: VALIDATION_TYPES.DEFAULT_OPTION_VALUE,
      searchText: VALIDATION_TYPES.TEXT,
      customValidationRule: VALIDATION_TYPES.TEXT,
      customErrorMessage: VALIDATION_TYPES.TEXT,
      icon: VALIDATION_TYPES.ICONS,
    };
  }

  static getDerivedPropertiesMap() {
    return {
      defaultTransformation: /*javascript*/ `{{ (() => {
        if (!Array.isArray(this.options)) return;
        if (
          this.options.every((o) =>
            _.isObject(o) &&
            (typeof o.value === "string" || typeof o.value === "number") &&
            typeof o.label === "string"
          )
        ) return;
        for (const option of this.options) {
          if (_.isObject(option) && Object.keys(option).length) {
            const key = Object.keys(option).sort()[0];
            return { value: key, label: key };
          }
        }
        return;
      })() }}`,
      transformedOptions: /*javascript*/ `{{
        (() => {
          ${normalizeFunctionStringified}
          const useEntireObjectForValue = this.transformation?.value === "$$ENTIRE_OBJECT";
          const options = this.transformation && this.defaultTransformation ?
            this.options.map(option => ({
              value: useEntireObjectForValue ? option : option[this.transformation.value ?? this.defaultTransformation.value],
              label: option[this.transformation.label],
              icon: option[this.transformation.icon]
            }))
          : this.defaultTransformation ?
            this.options.map(option => ({
              value: option[this.defaultTransformation.value],
              label: option[this.defaultTransformation.label],
            }))
            : this.options;

            return options.map(option => ({
              ...option,
              normalizedValue: normalizeFunction(option.value, this.transformation?.value)
            }));
        })()
      }}`,
      validationErrors: /*javascript*/ `{{
        (() => {
          let errors = {};
          if (this.isRequired) {
            if (this.isMultiple && this.selectedIndexArr && this.selectedIndexArr.length === 0) {
              errors.isRequired = "This field is required";
            } else if (!this.isMultiple && !this.selectedOption) {
              errors.isRequired = "This field is required";
            }
          }
          if (
            this.customValidationRule &&
            this.customValidationRule.length &&
            this.customValidationRule === "false"
          ) {
            errors.customError = this.customErrorMessage || "This field is invalid";
          }
          return errors;
        })()
      }}`,
      isValid: /*javascript*/ `{{ Object.keys(this.validationErrors ?? {}).length === 0 }}`,
      selectedOptionValue: /*javascript*/ `{{ (() => {
        ${normalizeFunctionStringified}
        if (this.isMultiple) return;
        const normalizedValue = normalizeFunction(this.metaSelectedOptionValue, this.transformation?.value); 
        const option = _.find(this.transformedOptions, { normalizedValue });
        if (option) return option.value;
      })() }}`,
      selectedOptionValues: /*javascript*/ `{{ (() => {
        ${normalizeFunctionStringified}
        if (!this.isMultiple) return;
        const normalize = (val) => normalizeFunction(val, this.transformation?.value);
        const selectedOpts = Array.isArray(this.metaSelectedOptionValueArr) ? this.metaSelectedOptionValueArr : [this.metaSelectedOptionValueArr];
        return this.transformedOptions.filter(opt => _.some(selectedOpts, (v) => _.isEqual(opt.normalizedValue, normalize(v)))).map(opt => opt.value);
      })() }}`,
      selectedOption: /*javascript*/ `{{ (() => {
        ${normalizeFunctionStringified}
        if (this.isMultiple) return;
        const normalizedValue = normalizeFunction(this.metaSelectedOptionValue, this.transformation?.value);
        return _.find(this.transformedOptions, { normalizedValue })
      })() }}`,
      selectedOptionArr: /*javascript*/ `{{ (() => {
        ${normalizeFunctionStringified}
        if (!this.isMultiple) return;
        const normalize = (val) => normalizeFunction(val, this.transformation?.value);
        return this.transformedOptions.filter(opt => _.some(this.metaSelectedOptionValueArr, (v) =>  _.isEqual(opt.normalizedValue, normalize(v))));
      })() }}`,
      selectedIndex: /*javascript*/ `{{ (() => {
        ${normalizeFunctionStringified}
        const normalizedValue = normalizeFunction(this.metaSelectedOptionValue, this.transformation?.value);
        return _.findIndex(this.transformedOptions, { normalizedValue: this.selectedOption ? normalizedValue : '' } ) 
      })() }}`,
      selectedIndexArr: /*javascript*/ `{{ (() => {
        ${normalizeFunctionStringified}
        const normalize = (val) => normalizeFunction(val, this.transformation?.value);
        return this.selectedOptionArr ? this.selectedOptionValues.map(o => _.findIndex(this.transformedOptions, { normalizedValue: normalize(o) })) : [] 
      })() }}`,
      value: /*javascript*/ `{{ this.isMultiple ? this.selectedOptionArr : this.selectedOptionValue }}`,
    };
  }

  static getDefaultPropertiesMap(): Record<string, string> {
    return {
      metaSelectedOptionValue: "defaultOptionValue",
      metaSelectedOptionValueArr: "defaultOptionValue",
    };
  }

  static getMetaPropertiesMap(): Record<string, any> {
    return {
      selectedOptionValue: undefined,
      metaSelectedOptionValue: undefined,
      metaSelectedOptionValueArr: undefined,
      searchText: undefined,
      isTouched: false,
    };
  }

  hasInvalidProps() {
    const allowlist = [];
    if (!this.props.isMultiple) {
      allowlist.push("selectedOptionValues");
    }
    return hasInvalidPropsInComponent(this.props.invalidProps, allowlist);
  }

  getErrorMessage() {
    const allowlist = [];
    if (!this.props.isMultiple) {
      allowlist.push("selectedOptionValues");
    }
    return getComponentErrorMessage(this.props.invalidProps, allowlist);
  }

  getSelectedOptionValueArr(): DropdownWidgetOptionValue[] {
    return Array.isArray(this.props.selectedOptionValues)
      ? this.props.selectedOptionValues
      : [];
  }

  handleFocusChange = (focusState: boolean) => {
    this.disableNudge(focusState);
    if (!this.props.isTouched && !focusState) {
      this.props.updateWidgetMetaProperty("isTouched", true);
    }
  };

  getIsInvalid = () => {
    if (!this.props.isTouched) return false;
    if (
      this.props.validationErrors?.isRequired &&
      Object.keys(this.props.validationErrors ?? {}).length === 1
    ) {
      // avoid flashing red if something was actually selected (isValid will be evaluated slower)
      const nothingSelected =
        this.props.metaSelectedOptionValue == null ||
        this.props.metaSelectedOptionValue === -1 ||
        (Array.isArray(this.props.metaSelectedOptionValueArr) &&
          this.props.metaSelectedOptionValueArr.length === 0);
      return nothingSelected && this.props.isRequired;
    }
    return !this.props.isValid;
  };

  getPageView() {
    const options = castArray(this.props.transformedOptions)
      .filter((opt) => isPlainObject(opt))
      .map((option) => ({
        ...option,
        label:
          typeof option.label === "object"
            ? JSON.stringify(option.label)
            : option.label,
      }));

    const normalizedMetaSelectedOptionValue = this.normalize(
      this.props.metaSelectedOptionValue,
    );

    const selectedIndex = options.findIndex((opt) =>
      isEqual(opt.normalizedValue, normalizedMetaSelectedOptionValue),
    );

    let computedSelectedIndexArr = [];
    if (isArray(this.props.metaSelectedOptionValueArr)) {
      computedSelectedIndexArr =
        this.props.metaSelectedOptionValueArr
          ?.map((o: DropdownWidgetOptionValue) =>
            options.findIndex((opt) =>
              isEqual(opt.normalizedValue, this.normalize(o)),
            ),
          )
          .filter((value) => value !== -1) ?? [];
    } else {
      // default value case for multiselect
      const idx = options.findIndex((opt) =>
        isEqual(opt.normalizedValue, normalizedMetaSelectedOptionValue),
      );
      computedSelectedIndexArr = idx === -1 ? [] : [idx];
    }
    const { componentWidth, componentHeight } = getComponentDimensions(
      this.props,
    );
    const hasLabel = Boolean(this.props.label?.trim?.()?.length);

    const isInvalid = this.getIsInvalid();
    return (
      <DropdownStyleOverrides
        $height={componentHeight}
        hasLabel={hasLabel}
        vertical={this.props.isVertical}
      >
        <DropdownComponentWithLayoutManaged
          canvasMode
          dataTest={`dropdown-${this.props.widgetName}`}
          onOptionSelected={this.onOptionSelected}
          onOptionRemoved={this.onOptionRemoved}
          onSelectAll={this.onSelectAll}
          widgetId={this.props.widgetId}
          placeholder={this.props.placeholderText}
          options={options}
          height={componentHeight}
          width={componentWidth}
          selectionType={
            this.props.isMultiple ? "MULTI_SELECT" : "SINGLE_SELECT"
          }
          selectedIndex={selectedIndex}
          selectedIndexArr={computedSelectedIndexArr}
          label={hasLabel ? this.props.label : undefined}
          isLoading={this.props.isLoading}
          isRequired={this.props.isRequired}
          disabled={this.props.isDisabled}
          vertical={this.props.isVertical}
          allowClearing={this.props.allowClearing}
          clientSideFiltering={this.props.clientSideFiltering ?? true}
          allowSelectAll={this.props.allowSelectAll}
          isInvalid={isInvalid}
          validationError={
            isInvalid
              ? Object.values(this.props.validationErrors ?? {})[0]
              : undefined
          }
          onFocusChange={this.handleFocusChange}
          onSearchTextChange={this.onDebouncedSearchTextChange}
          icon={this.props.icon}
          labelProps={this.props.labelProps}
          inputProps={this.props.inputProps}
        />
      </DropdownStyleOverrides>
    );
  }

  normalize = (val: DropdownWidgetOptionValue) =>
    normalizeFunction(val, this.props.transformation?.value);

  onOptionSelected = (selectedOption: DropdownOption | null) => {
    if (selectedOption === null) {
      // If the selected option is null, it means the user has cleared the dropdown
      // So here we only trigger `onClear`, not `onOptionChange`.
      if (this.props.selectedOption) {
        this.props.updateWidgetMetaProperty("metaSelectedOptionValue", -1);
        if (!this.props.isMultiple) {
          this.onClear();
        }
      }
      return;
    }

    let isChanged = true;
    if (!this.props.isMultiple) {
      // Check if the value has changed. If no option
      // selected till now, there is a change
      if (this.props.selectedOption) {
        isChanged = !isEqual(
          this.normalize(this.props.selectedOption.value),
          selectedOption.normalizedValue,
        );
      }
      if (isChanged) {
        this.props.updateWidgetMetaProperty(
          "metaSelectedOptionValue",
          selectedOption.value,
          createRunEventHandlersPayloadOptional({
            steps: this.props.onOptionChange,
            currentScope: ApplicationScope.PAGE,
            type: EventType.ON_OPTION_CHANGE,
            entityName: this.props.widgetName,
          }),
        );
      }
    } else {
      const selectedOptionValueArr = this.getSelectedOptionValueArr();
      const isAlreadySelected = selectedOptionValueArr.some((v) =>
        isEqual(this.normalize(v), selectedOption.normalizedValue),
      );

      let newSelectedValue = [...selectedOptionValueArr];
      if (isAlreadySelected) {
        newSelectedValue = newSelectedValue.filter(
          (v) => !isEqual(this.normalize(v), selectedOption.normalizedValue),
        );
      } else {
        newSelectedValue.push(selectedOption.value);
      }
      this.props.updateWidgetMetaProperty(
        "metaSelectedOptionValueArr",
        newSelectedValue,
        createRunEventHandlersPayloadOptional({
          steps: this.props.onOptionChange,
          currentScope: ApplicationScope.PAGE,
          type: EventType.ON_OPTION_CHANGE,
          entityName: this.props.widgetName,
        }),
      );
    }
    if (!this.props.isTouched) {
      this.props.updateWidgetMetaProperty("isTouched", true);
    }
  };

  onSelectAll = (options: DropdownOption[]) => {
    const optionValues = options.map(({ value }) => value);
    let exec: RunEventHandlersPayload | undefined;
    if (this.props.onOptionChange && options.length > 0) {
      exec = createRunEventHandlersPayload({
        steps: this.props.onOptionChange,
        currentScope: ApplicationScope.PAGE,
        type: EventType.ON_OPTION_CHANGE,
        entityName: this.props.widgetName,
      });
    }
    this.props.updateWidgetMetaProperty(
      "metaSelectedOptionValueArr",
      optionValues,
      exec,
    );
    if (options.length === 0) {
      this.onClear();
    }
  };

  onOptionRemoved = (removedIndex: number) => {
    const newSelectedValue = this.props.metaSelectedOptionValueArr.filter(
      (_, idx) => idx !== removedIndex,
    );
    this.props.updateWidgetMetaProperty(
      "metaSelectedOptionValueArr",
      newSelectedValue,
      createRunEventHandlersPayloadOptional({
        steps: this.props.onOptionChange,
        currentScope: ApplicationScope.PAGE,
        type: EventType.ON_OPTION_CHANGE,
        entityName: this.props.widgetName,
      }),
    );
  };

  private onClear() {
    if (!this.props.isTouched) {
      this.props.updateWidgetMetaProperty("isTouched", true);
    }
    if (this.props.onClear) {
      this.runEventHandlers({
        steps: this.props.onClear,
        type: EventType.ON_CLEAR,
        additionalNamedArguments: {},
      });
    }
  }

  private onSearchTextChange = (searchText: string) => {
    this.props.updateWidgetMetaProperty("searchText", searchText);
    if (this.props.onSearchTextChange) {
      this.runEventHandlers({
        steps: this.props.onSearchTextChange,
        type: EventType.ON_TEXT_CHANGE,
        additionalNamedArguments: {
          searchText,
        },
      });
    }
  };

  onDebouncedSearchTextChange = debounce(this.onSearchTextChange, 300);

  getWidgetType(): WidgetType {
    return "DROP_DOWN_WIDGET";
  }
}

export interface DropdownOptionsTransformation {
  label: string;
  value: string;
}

export type DropdownWidgetOptionValue = string | number | object;

interface DropdownWidgetOption extends Omit<DropdownOption, "normalizedValue"> {
  normalizedValue: string;
}

interface DropdownWidgetPersistedProps {
  defaultOptionValue?: string | string[];
  isMultiple: boolean;
  isRequired: boolean;
  clientSideFiltering: boolean;
  isVertical?: boolean;
  label?: string;
  onOptionChange?: MultiStepDef;
  /** the onClear event will be triggered only when the user explicitly clears dropdown and not when they remove the last selected option */
  onClear?: MultiStepDef;
  onSearchTextChange?: MultiStepDef;
  options: string;
  placeholderText?: string;
  allowClearing?: boolean;
  allowSelectAll?: boolean;
  transformation?: DropdownOptionsTransformation;
  icon?: string;
  labelProps?: {
    textStyle: TextStyleWithVariant;
  };
  inputProps?: {
    textStyle: TextStyleWithVariant;
  };
}

export interface DropdownWidgetProps
  extends DropdownWidgetPersistedProps,
    WidgetPropsRuntime,
    WithMeta {
  transformedOptions?: DropdownWidgetOption[];
  placeholderText?: string;
  selectedIndex?: number;
  selectedIndexArr?: number[];
  selectedOption: DropdownWidgetOption;
  selectedOptionValue: DropdownWidgetOptionValue;
  selectedOptionValues: DropdownWidgetOptionValue[];
  isValid?: boolean;
  validationErrors?: Record<string, string>;
  metaSelectedOptionValue: DropdownWidgetOptionValue;
  metaSelectedOptionValueArr: DropdownWidgetOptionValue[];
  customErrorMessage?: string;
  customValidationRule?: string;
  isTouched: boolean;
}

export default DropdownWidget;
export const ConnectedDropDownWidget = withMeta(DropdownWidget);
